export const PROMOSKU = "CAREKIT"
export const NDSKU = "NDGROWTHPLAN"
export const PROMOSCALP = "SCTRAVELPROMO"
export const PROMOSC = "SCALPSCPROMO-N01"
export const GACTIVATOR = "GACTIVATOR-N01"
export const MGACTIVATOR = "MGACTIVATOR-N01"

export const HAIRSERUM = "HAIRSERUM-N01"
export const MHAIRSERUM = "MHAIRSERUM-N01"
export const HAIRSERUMSML = "HAIRSERUMSML-N01"
export const MHAIRSERUMSML = "MHAIRSERUMSML-N01"

// NT2-19076 - July Deluxe Sample Gifting - 7/1/2024 - 7/31/2024
// NT2-20477 - September 1oz HS Gifting
export const SAMPLESKUS = [
  "RPURIFIER_SMPL",
  "SDEFENDER_SMPL",
  "HS_SMPL",
  "MHS_SMPL",
]
// End NT2-19076 - July Deluxe Sample Gifting - 7/1/2024 - 7/31/2024

export const FHKDUOS1 = [
  "WOMGSML1",
  "VEGGSML1",
  "BALGSML1",
  "POSGSML1",
  "MENGSML1",
]
export const FHKDUOS3 = [
  "WOMGSML3",
  "VEGGSML3",
  "BALGSML3",
  "POSGSML3",
  "MENGSML3",
]

export const SCALPSKUS = [
  "RPURIFIER",
  "SDEFENDER",
  "SCALPSCPROMO",
  "SCALPSCTRAVEL",
  "SCALPSC",
  "SCALPSCGK",
  "SCALPSCK",
  "SCALPEKGSML",
]
